import React from "react"
import PropTypes from "prop-types"
import HeaderComponent from "./header"
import FooterComponent from "./footer"
import { Helmet } from "react-helmet"

import "./layout.css"
import "./reset.css"
import "../fonts/fonts.css"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <script src="http://cdnjs.cloudflare.com/ajax/libs/gsap/1.18.0/TweenMax.min.js"></script>
      </Helmet>
      <div className="layout-container">
        <HeaderComponent />
        <div className="layout-main">{children}</div>
        <FooterComponent />
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
