import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import logo from "../../images/logo.png"
import activeLogo from "../../images/active-logo.png"

import "./index.css"

export default function Header() {
  const [navVal, setNavVal] = useState(false)
  const [navState, setNavState] = useState(false)
  let urlPath = ""
  if (typeof window !== `undefined`) {
    urlPath = window.location.pathname
  }

  useEffect(() => {
    window.addEventListener(
      "scroll",
      function () {
        let scrollTop = document.documentElement.scrollTop
        if (scrollTop >= 100) {
          setNavState(true)
        } else {
          setNavState(false)
        }
      },
      true
    )
  }, [])

  const handleNavbarToggle = () => {
    setNavVal(!navVal)
  }

  return (
    <div
      className={`header-container-wrapper ${
        navState
          ? urlPath === "/"
            ? "active-header"
            : "active-non-home-header"
          : urlPath === "/"
          ? "inactive-home-header"
          : ""
      }`}
    >
      <div className={`header-container`}>
        <div className="header-logo-section">
          <Link to="/">
            {navState || urlPath !== "/" ? (
              <img src={activeLogo} alt="logo" />
            ) : (
              <img src={logo} alt="logo" />
            )}
          </Link>
        </div>
        <div className="header-link-section">
          <div
            className={`icon-container ${navVal ? "icon-cross-container" : ""}`}
            onClick={handleNavbarToggle}
          >
            <div className="icon-bar1"></div>
            <div className="icon-bar2"></div>
            <div className="icon-bar3"></div>
          </div>
          <ul
            className={`${
              navVal ? "active-navbar-list" : "inactive-navbar-list"
            }`}
          >
            <li className="header-ls-list-element">
              <Link
                to="/solution/"
                className={`${
                  urlPath.includes("solution") ? "active-element" : ""
                }`}
              >
                Solution
              </Link>
            </li>
            <li className="header-ls-list-element">
              <Link
                to="/stories-awards/"
                className={`${
                  urlPath.includes("stories-awards") ? "active-element" : ""
                }`}
              >
                Stories & Awards
              </Link>
            </li>
            {/* <li className="header-ls-list-element">
              <Link to="/DAWN-Project/" className={`${urlPath.includes('DAWN-Project') ? 'active-element' : ''}`}>Project DAWN</Link>
            </li> */}
            <li className="header-ls-list-element">
              <Link
                to="/aboutus/"
                className={`${
                  urlPath.includes("aboutus") || urlPath.includes("people")
                    ? "active-element"
                    : ""
                }`}
              >
                About
              </Link>
            </li>
            <li className="header-ls-list-element">
              <Link
                to="/career/"
                className={`${
                  urlPath.includes("career") ? "active-element" : ""
                }`}
              >
                Career
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
