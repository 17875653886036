import React, { useState } from "react"
import { Link } from "gatsby"
import fbLogo from "../../images/fb.svg"
import ytLogo from "../../images/yt.svg"
import linkedinLogo from "../../images/linkedin.svg"
import instaLogo from "../../images/insta.svg"
import twitterLogo from "../../images/twitter.svg"

import "./index.css"
import googlePlay from "../../images/google.png"
import appleApp from "../../images/apple.png"

export default function Footer() {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleClick = e => {
    e.preventDefault()
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbwTg_qu3mWtJMSQGGcwNoX9V2WVmm3X1x0OB00Rx7yvJYRbJ4p6/exec"
    let formData = new FormData()
    formData.append("email", email)
    fetch(scriptURL, { method: "POST", body: formData })
      .then(response => {
        setEmail("")
        setSuccess(true)
      })
      .catch(error => setError(true))
  }

  return (
    <div className="footer-container">
      <div className="footer-upper-section">
        <div className="footer-us-column company-width">
          <div className="footer-us-column-header">COMPANY</div>
          <div className="footer-us-column-content display-flex">
            <div className="footer-us-column-content-sub-column padding-right">
              <div>
                <Link to="/solution/">Solution</Link>
              </div>
              <div>
                <Link to="/stories-awards/">Awards & Stories</Link>
              </div>
              <div>
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </div>
            </div>
            <div className="footer-us-column-content-sub-column">
              <div>
                <Link to="/aboutus/">About Us</Link>
              </div>
              <div>
                <Link to="/career/">Careers</Link>
              </div>
              <div>
                <Link to="/terms-and-conditions/">Terms & Conditions</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-us-column padding-right">
          <div className="footer-us-column-header">CONTACT</div>
          <div className="footer-us-column-content">
            <div className="">
              <span className="bold-text">Phone No.</span> 08048167654
            </div>
            <div>
              <span className="bold-text">E-mail.</span> contact@mooofarm.com
            </div>
          </div>
        </div>
        <div className="footer-us-column">
          <div className="footer-us-column-header">ADDRESS</div>
          <div className="footer-us-column-content">
            Plot No. 105, Institutional Area,
            <br />
            Sector 44, Gurugram,
            <br />
            India, 122001
          </div>
        </div>
      </div>
      <div className="footer-lower-section display-flex">
        <div className="footer-ls-icon">
          <div className="footer-ls-icon-wrapper display-flex">
            <a target="_blank" href="https://www.facebook.com/mooofarm/">
              <img className="footer-ls-logo" src={fbLogo} alt="fb-logo" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC1fxWvTk-xYbBAwfQvUCJAQ"
            >
              <img className="footer-ls-logo" src={ytLogo} alt="yt-logo" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/mooofarm">
              <img
                className="footer-ls-logo"
                src={linkedinLogo}
                alt="linkedin-logo"
              />
            </a>
            <a target="_blank" href="https://www.instagram.com/mooofarm/">
              <img
                className="footer-ls-logo"
                src={instaLogo}
                alt="insta-logo"
              />
            </a>
            <a target="_blank" href="https://twitter.com/mooofarm?s=20">
              <img
                className="footer-ls-logo"
                src={twitterLogo}
                alt="twitter-logo"
              />
            </a>
          </div>
        </div>
        <div className="footer-ls-news">
          <div className="footer-ls-news-heading">
            Subscribe to our Newsletter
          </div>
          {success ? (
            <div className="success-msg">Successfully subscribed!</div>
          ) : (
            <></>
          )}
          {error ? (
            <div className="error-msg">Something went wrong!</div>
          ) : (
            <></>
          )}
          <form id="email-form">
            <input
              type="email"
              className="footer-email-input"
              placeholder="Email ID"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <button className="footer-subs-button" onClick={handleClick}>
              Subscribe
            </button>
          </form>
        </div>
        <div className="footer-ls-buttons">
          <div>
            <a
              target="_blank"
              href={"https://play.google.com/store/apps/details?id=com.moo"}
            >
              <img src={googlePlay} alt={"google-play-button"} />
            </a>
          </div>
          {/*<div><a target="_blank" href={"#"}><img src={appleApp} alt={"apple-app-button"} /></a></div>*/}
        </div>
      </div>
    </div>
  )
}
